// NotAuthorized.js
import React from "react";
import { useNavigate } from "react-router-dom";

const NotAuthorized = () => {
    const navigate = useNavigate();

    return (
        <div style={styles.container}>
            <h1 style={styles.heading}>Access Denied</h1>
            <p style={styles.message}>You do not have permission to view this page.</p>
            <button onClick={() => navigate("/")} style={styles.button}>
                Return to Home
            </button>
        </div>
    );
};

const styles = {
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        backgroundColor: "#f8d7da",
        color: "#721c24",
    },
    heading: {
        fontSize: "2.5em",
        fontWeight: "bold",
        marginBottom: "10px",
    },
    message: {
        fontSize: "1.2em",
        marginBottom: "20px",
        textAlign: "center",
    },
    button: {
        padding: "10px 20px",
        fontSize: "1em",
        color: "#fff",
        backgroundColor: "#721c24",
        border: "none",
        cursor: "pointer",
        borderRadius: "5px",
    },
};

export default NotAuthorized;
