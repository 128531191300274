// src/components/MainLayout.js
import React, { useState, useEffect, useRef } from "react";
import { useAuth } from "../AuthContext";
import { useNavigate } from "react-router-dom";

const MainLayout = ({ children }) => {
    const { username, logout } = useAuth(); // Retrieve username from AuthContext
    const navigate = useNavigate();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);

    const handleLogout = () => {
        logout();
        navigate("/home");
    };

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div>
            {/* Top bar with user dropdown */}
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "10px 20px",
                    background: "#f0f0f0",
                    borderBottom: "1px solid #ccc",
                }}
            >
                <h2>FinWeb</h2>

                {/* User Dropdown */}
                <div style={{ position: "relative", cursor: "pointer" }} ref={dropdownRef}>
                    <div onClick={toggleDropdown} style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ marginRight: "10px" }}>{username}</span> {/* Display username */}
                        <span style={{ fontSize: "16px" }}>▼</span>
                    </div>

                    {/* Dropdown Menu */}
                    {dropdownOpen && (
                        <div
                            style={{
                                position: "absolute",
                                top: "100%",
                                right: 0,
                                backgroundColor: "#fff",
                                border: "1px solid #ccc",
                                boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
                                borderRadius: "5px",
                                zIndex: 10,
                                minWidth: "150px",
                            }}
                        >
                            <div
                                onClick={handleLogout}
                                style={{
                                    padding: "10px 20px",
                                    cursor: "pointer",
                                    backgroundColor: "#FF6347",
                                    color: "#fff",
                                    fontWeight: "bold",
                                    textAlign: "center",
                                    borderRadius: "0 0 5px 5px",
                                }}
                                onMouseEnter={(e) => (e.target.style.backgroundColor = "#d9534f")}
                                onMouseLeave={(e) => (e.target.style.backgroundColor = "#FF6347")}
                            >
                                Logout
                            </div>
                        </div>
                    )}
                </div>
            </div>

            {/* Render page-specific content */}
            <div>{children}</div>
        </div>
    );
};

export default MainLayout;
