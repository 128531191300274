// App.js
import "./App.css";
import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "./components/Home";
import Login from "./components/Login";
import Signup from "./components/Signup";
import PriceDeviationHistogram from "./components/PriceDeviationHistogram";
import PrivateRoute from "./components/PrivateRoute";
import NotAuthorized from "./components/NotAuthorized";
import AuthorizationRoute from "./components/AuthorizationRoute";
import PriceDeviationHistogramInternal from "./components/PriceDeviationHistogramInternal";

const App = () => {
    return (
        <div className="App">
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<Signup />} />
                <Route
                    path="/pricehistogram"
                    element={
                        <PrivateRoute>
                            <AuthorizationRoute>
                                <PriceDeviationHistogramInternal />
                            </AuthorizationRoute>
                        </PrivateRoute>
                    }
                />
                <Route path="/not-authorized" element={<NotAuthorized />} />
                <Route path="*" element={<Navigate to="/" />} /> {/* Redirect any undefined route to Home */}
            </Routes>
        </div>
    );
};

export default App;
