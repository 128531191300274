// AuthorizationRoute.js
import React from "react";
import {Navigate} from "react-router-dom";

// Utility to check if the user is authorized
const isAuthorized = () => {
    const username = localStorage.getItem("username");
    const role = localStorage.getItem("role");
    console.log(`Username is :${username}, role is: ${role}`);
    return role === "admin"; // Ensure the user's role is "admin"
};

// AuthorizationRoute component
const AuthorizationRoute = ({ children }) => {
    return isAuthorized() ? children : <Navigate to="/not-authorized" />; // Redirect to a "not-authorized" page if unauthorized
};

export default AuthorizationRoute;
