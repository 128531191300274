// src/components/Home.js
import React from "react";
import { Link } from "react-router-dom";
import PriceDeviationHistogram from "./PriceDeviationHistogram";
import "./Home.css"; // Add relevant styles here

const Home = () => {
    return (
        <div className="home-page">
            {/* Top-right login and signup options */}
            <div className="auth-buttons">
                <Link to="/login"><button className="auth-button">Login</button></Link>
                <Link to="/signup"><button className="auth-button signup">Sign Up</button></Link>
            </div>

            {/* PriceDeviationHistogram section */}
            <div className="histogram-section">
                <PriceDeviationHistogram />
            </div>
        </div>
    );
};

export default Home;
