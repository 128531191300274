// src/AuthContext.js
import React, { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [token, setToken] = useState(null);
    const [username, setUsername] = useState(null); // New state for username
    const [role, setUserRole] = useState(null);
    const navigate = useNavigate();

    const login = (token, username, role) => { // Accept username as parameter
        setToken(token);
        setUsername(username); // Set username
        setUserRole(role);
        localStorage.setItem("token", token);
        localStorage.setItem("username", username); // Store username
        localStorage.setItem("role", role);
    };

    const logout = () => {
        setToken(null);
        setUsername(null); // Clear username
        localStorage.removeItem("token");
        localStorage.removeItem("username");
        localStorage.removeItem("role");
        navigate("/home");
    };

    return (
        <AuthContext.Provider value={{ token, username, role, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
